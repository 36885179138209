<template>
  <div id="app">
    <v-app app>
      <v-app-bar app>
        <!-- Menu small screen -->
        <v-app-bar-nav-icon
          @click="drawer = true"
          class="d-flex d-sm-none"
        ></v-app-bar-nav-icon>
        <v-img max-height="50" max-width="250" :src="Logo"></v-img>
        <v-spacer></v-spacer>

        <template v-slot:extension>
          <!-- Menu medium screen and above -->
          <v-tabs
            color="#A3A63D"
            v-model="tab"
            align-with-title
            class="d-none d-sm-flex"
          >
            <v-tabs-slider color="#8C7C2A"></v-tabs-slider>

            <v-tab
              v-for="item in items"
              :key="item.name"
              v-scroll-to="{ el: item.id, offset: -110 }"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
      <!-- Add a navigation bar -->
      <v-navigation-drawer v-model="drawer" app temporary>
        <v-list nav dense>
          <v-list-item-group>
            <v-list-item
              v-for="(item, index) in items"
              :key="item.name"
              v-scroll-to="{ el: item.id, offset: -110 }"
            >
              <v-list-item-title @click="tab = index">{{
                item.name
              }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <!-- Content -->
      <v-main>
        <v-carousel :show-arrows="false" :cycle="true">
          <v-carousel-item
            v-for="(item, i) in photos"
            :key="i"
            :src="item.src"
            interval="10000"
          ></v-carousel-item>
        </v-carousel>
        <Welkom id="welkom"></Welkom>
        <VoorWie id="voorwie"></VoorWie>
        <Werkwijze id="werkwijze"></Werkwijze>
        <WieZijnWij id="wiezijnwij"></WieZijnWij>
        <Aanmelding id="aanmelding"></Aanmelding>
        <Vergoeding id="vergoeding"></Vergoeding>
        <Contact id="contact"></Contact>
      </v-main>
      <v-footer class="justify-center">
        <h3>{{ new Date().getFullYear() }} - Domijn</h3>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import Vue from "vue";
import Welkom from "./components/welkom.vue";
import VoorWie from "./components/voorwie.vue";
import Werkwijze from "./components/werkwijze.vue";
import WieZijnWij from "./components/wiezijnwij.vue";
import Aanmelding from "./components/aanmelding.vue";
import Vergoeding from "./components/vergoeding.vue";
import Contact from "./components/contact.vue";
import * as VueGoogleMaps from "vue2-google-maps";

var VueScrollTo = require("vue-scrollto");

Vue.use(VueScrollTo);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBnzoVDdQPvXG8bGBJSvYFHEQp3zRgQTfs",
    libraries: "places",
  },
  installComponents: true,
});

export default {
  name: "App",

  components: {
    Welkom,
    VoorWie,
    Werkwijze,
    WieZijnWij,
    Aanmelding,
    Vergoeding,
    Contact,
  },

  data() {
    return {
      photos: [
        {
          src: require("./assets/domijn/domijn.jpg"),
        },
        {
          src: require("./assets/domijn/domijn2.jpg"),
        },
        {
          src: require("./assets/domijn/domijn3.jpg"),
        },
      ],
      Logo: require("./assets/domijn_logov1.png"),
      drawer: false,
      tab: null,
      items: [
        { name: "welkom", id: "#welkom" },
        { name: "voor wie", id: "#voorwie" },
        { name: "werkwijze", id: "#werkwijze" },
        { name: "wie zijn wij", id: "#wiezijnwij" },
        { name: "aanmelding", id: "#aanmelding" },
        { name: "vergoeding", id: "#vergoeding" },
        { name: "contact", id: "#contact" },
      ],
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Quicksand:400,600");

.con {
  max-width: 1600px;
  padding-top: 20px;
  padding-bottom: 60px;
}

h3,
h4,
li {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
}

h1,
h2,
div {
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
}

.bg {
  padding: 20px;
  width: 100vw;
}

font-awesome-icon {
  color: #8c7c2a;
}

h1 {
  text-align: center;
  justify-self: center;
  color: #8c7c2a;
}

h2 {
  color: #a3a63d;
}

.light-text {
  color: #f3f8e2;
}

footer {
  height: 100px;
}
</style>
