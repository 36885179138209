<template>
<div class="bg">
  <v-container class="con">
    <h1>Wat biedt Domijn?</h1>
    <h4 class="pt-4">{{ text1 }}</h4>
    <h4 class="pt-4">{{ text2 }}</h4>
    <h4 class="pt-4">{{ text3 }}</h4>
    <h4>{{ text4 }}</h4>
    <v-row class="pt-8" justify="center">
      <!-- Placeholder 1 -->
      <a 
        target="_blank"
        href="https://lvvp.info/lid/11945/?praktijk=eerstepraktijk">
        <v-img :src="lvvp_vis" 
        height="150" 
        width="150"
        class="ma-10"
        aspect-ratio="1.7"
        contain></v-img>
      </a>
      <!-- Placeholder 2 -->
      <a
        target="_blank"
        href="https://www.bigregister.nl/"
      >
        <v-img 
        :src="cibg" 
        height="150" 
        width="150"
        class="ma-10"
        aspect-ratio="1.7"
        contain></v-img>
      </a>
      <!-- Placeholder 3 -->
      <a
        target="_blank"
        href="http://www.ru.nl/"
      >
        <v-img :src="radboud" 
        height="150" 
        width="150"
        class="ma-10"
        aspect-ratio="1.7"
        contain></v-img>
      </a>
      <!-- Placeholder 4 -->
      <a
        target="_blank"
        href="http://rinozuid.nl/"
      >
        <v-img :src="rinozuid" 
        height="150" 
        width="150"
        class="ma-10"
        aspect-ratio="1.7"
        contain></v-img>
      </a>
    </v-row>
    <v-row justify="center">
      <!-- Placeholder 1 -->
      <a
        target="_blank"
        href="http://www.emdr.nl/"
      >
        <v-img :src="emdr" 
        height="150" 
        width="150"
        class="ma-10"
        aspect-ratio="1.7"
        contain></v-img>
      </a>
      <!-- Placeholder 2 -->
      <a
        target="_blank"
        href="http://www.psynip.nl/"
      >
        <v-img :src="nip" 
        height="150" 
        width="150"
        class="ma-10"
        aspect-ratio="1.7"
        contain></v-img>
      </a>
      <!-- Placeholder 3 -->
      <a
        target="_blank"
        href="https://www.lvvp.info/home/"
      >
        <v-img :src="lvvp" 
        height="150" 
        width="150"
        class="ma-10"
        aspect-ratio="1.7"
        contain></v-img>
      </a>
      <!-- Placeholder 4 -->
      <a
        target="_blank"
        href="https://web.axians.nl/"
      >
        <v-img :src="axians" 
        height="150" 
        width="150"
        class="ma-10"
        aspect-ratio="1.7"
        contain></v-img>
      </a>
    </v-row>
  </v-container>
</div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Werkwijze",
  data() {
    return {
      text1:
        "Alle behandelingen vallen bij elke zorgverzekering onder de basisvergoedingen. U hoeft dus geen aanvullende verzekering te hebben om psychologische hulp te mogen ontvangen. Na aanmelding en een intake krijgt u een adviesgesprek. Met uw hulpverlener bespreekt u hierin wat de voorlopige diagnose is en welke behandelingen daar bij kunnen passen. Samen met de hulpverlener maakt u de beslissing over de start van een behandelprogramma, de duur daarvan en de doelen die bereikt kunnen worden. Bij elke cliënt is dat anders.",
      text2:
        "Domijn biedt cognitieve gedragstherapie, schematherapie, EMDR-therapie, emotieregulatietrainingen, beeldende therapie, groepstherapie, ADHD-coaching, medicatie-adviezen.",
      text3:
        "Zo nodig wordt extra diagnostiek ingepland. U kunt denken aan intelligentie-onderzoek en persoonlijkheidsonderzoek. Voor autisme-onderzoek bestaat een wachtlijst van meer dan een jaar.",
      text4:
        "De start van de behandeling kan beginnen als het behandelplan er ligt. Deze sluit direct  aan op de intakeperiode. Er zijn behandeltrajecten die 6-10 sessies duren. Er zijn ook langere trajecten mogelijk wanneer in de intake blijkt dat er complexe problemen aan de orde zijn. Dan kan een behandeling wel 1-3 jaar duren.",
      axians: require("../assets/logos/axians.png"),
      cibg: require("../assets/logos/cibg.svg"),
      emdr: require("../assets/logos/EMDR.png"),
      lvvp: require("../assets/logos/lvvp.svg"),
      lvvp_vis: require("../assets/logos/lvvp-visitatie.png"),
      nip: require("../assets/logos/nip.svg"),
      radboud: require("../assets/logos/radboud.svg"),
      rinozuid: require("../assets/logos/RinoZuid.png"),
    };
  },
});
</script>

<style scoped>

v-img {
  margin: 50px
}

</style>
