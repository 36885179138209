<template>
  <div class="bg">
    <v-container class="con">
      <h1 class="light-text">Wie zijn wij?</h1>
      <v-card
        color="rgb(255, 255, 255, 0.2)"
        class="ma-6 pa-6"
        v-for="collega in collegas"
        :key="collega.naam"
      >
        <v-row align="center" justify="center">
          <v-col cols="12" md="3" justify-center align-center>
            <v-img :src="collega.foto" width="200px" height="300px"></v-img>
            <h3 class="pt-4">{{ collega.naam }}</h3>
            <h4>{{ collega.functie }}</h4>
            <h4>{{ collega.big }}</h4>
          </v-col>
          <v-col cols="12" md="9">
            <h4 class="pt-4">{{ collega.text1 }}</h4>
            <h4 class="pt-4">{{ collega.text2 }}</h4>
            <h4 class="pt-4">{{ collega.text3 }}</h4>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "WieZijnWij",
  data() {
    return {
      model: 0,
      collegas: [
        {
          naam: "Ingrid Reukers",
          functie: "Klinisch psycholoog en praktijkhouder.",
          big: "BIGnummer KP 89051105125",
          text1:
            "In 2015 heb ik deze praktijk opgericht. Ik ben de praktijkhouder en eindverantwoordelijke voor alle behandelingen.",
          text2:
            "Als persoon ben ik rechtdoorzee, creatief, transparant, stoer en humorvol. Binnen het werk als hulpverlener wil ik mensen leren om zichzelf meer serieus te nemen; meer te huilen, lachen, boos te zijn en ook om hard te werken aan hun klachten. Het is mooi om telkens weer te ontdekken dat geen mens hetzelfde is en dat alle mensen over meer veerkracht beschikken dan ze vaak zelf verwachten.",
          text3:
            "Diagnostiek, Schematherapie en EMDRtherapie zijn de grootste pijlers van mijn werk bij Domijn. Er werken zo'n acht mensen actief mee aan deze praktijk en ik vind het een leuk en creatief team.",
          foto: require("../assets/collegas/Ingrid.jpg"),
        },
        {
          naam: "Dineke Doeven",
          functie: "Verpleegkundig specialist",
          big: "BIGnummer 19008097930",
          text1:
            "Als Verpleegkundig Specialist heb ik een ruime ervaring met allerlei soorten van psychische en psychiatrische klachten. Mijn bijdrage aan de behandelingen van Domijn is divers. De technieken die ik toepas zijn o.a. Dialectische Gedrags Therapie, Schema Focus Therapie, EMDR en Farmacotherapie. Ik werk graag samen met mijn collega’s van Domijn, we zijn een vaardig team met veel in huis en bovendien is het gezellig.",
          text2: "",
          text3: "",
          foto: require("../assets/collegas/Dineke.jpg"),
        },
        {
          naam: "Hetty Kaanders",
          functie: "Gezondheidszorg psycholoog",
          big: "BIGnummer 69051717325",
          text1:
            "In mijn werk word ik gedreven door mijn oprechte belangstelling voor mensen: ik ben graag met mensen in gesprek. Ik heb ruime ervaring bij de GGZ. Ik werk in Einhoven in de Ouderenpsychiatrie en daarnaast werk ik met veel plezier onder de vlag van Domijn. Deze sfeervolle praktijk is een plek waar iedereen tot zijn recht komt. Ik vind het erg boeiend om een stukje mee op te mogen lopen in het leven van mijn cliënten. Ik heb ruime ervaring met het behandelen van diverse psychische en psychiatrische problematiek zoals depressie, angst-stoornissen, traumagerelateerde problematiek en persoonlijkheidsproblematiek.",
          text2: "",
          text3: "",
          foto: require("../assets/collegas/Hetty.jpg"),
        },
        {
          naam: "Vivi Ament",
          functie: "Beeldend therapeut",
          big: "",
          text1:
            "Naast het beeldend werken, heb ik me verdiept in de cognitieve gedragstherapie. Dit is een heldere, directe en concrete manier van werken die ontzettend cliëntgericht is.",
          text2:
            "Onlangs heb ik de schematherapie kunnen toevoegen aan mijn manier van werken. Deze therapievorm geeft zoveel verdiepen maar ook mogelijkheden. Deze mogelijkheden laten zich heel goed combineren met beeldend werken en de CGT.",
          text3:
            "In mijn praktijk combineer ik beeldend werken met cognitieve gedragstherapie en schematherapie. Een zeer krachtige combinatie!",
          foto: require("../assets/collegas/Vivi.jpg"),
        },
        {
          naam: "Margo Verhoeven",
          functie: "Ervaringsdeskundige",
          big: "",
          text1:
            "Hallo ik ben Margo, ik ben 63 jaar en ik werk als ervaringsdeskundige bij Domijn. Ik word waar nodig ingezet door Ingrid bij cliënten met PTSS; eetstoornissen en autisme, want daarmee ben ik het meest bekend.",
          text2:
            "Ik heb de nodige  trainingen  gevolgd om een luisterend  oor te zijn en waar nodig,  wat begeleiding / ondersteuning  te bieden.  Ik ben een open, eerlijk en een optimistische vrouw. Ik geef niet snel op.",
          text3:
            "Ik zet me graag in voor iedereen die even een ruggensteuntje nodig heeft. Elke maand begeleid ik op de dinsdagavond een groep voor vrouwen met autisme. Wil je meedoen, dan ben je welkom!",
          foto: require("../assets/collegas/Margo.jpg"),
        }
      ],
    };
  },
});
</script>

<style scoped>
.bg {
  background-color: #a7955e;
}
</style>
