<template>
  <div class="bg">
    <v-container class="con">
      <v-dialog
        :value="dialog"
        transition="dialog-top-transition"
        max-width="600"
      >
        <template>
          <v-card>
            <v-toolbar color="#A3A63D" dark
              >Email succesvol verzonden</v-toolbar
            >
            <v-card-text>
              <h3 class="mt-4">
                Domijn heeft uw bericht ontvangen. We zullen zo spoedig mogelijk
                contact opnemen met u opnemen.
              </h3>
              <h3 class="mt-2">Naam: {{ naam }}</h3>
              <h3>Email: {{ email }}</h3>
              <h3>Telefoon: {{ telefoon }}</h3>
              <h3>Plaats: {{ plaats }}</h3>
              <h3 class="mt-2">Bericht:</h3>
              <h4>{{ bericht }}</h4>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="closeDialog">Sluiten</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <h1>Contact</h1>

      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" md="6" justify="center">
          <h4 class="pt-4">
            Domijn is gevestigd in Protonenlaan 10, 5405NE Uden
          </h4>
          <h4>secretariaat@domijn.org</h4>
          <h4>0413 760 560</h4>
          <h4>KVK 62841335</h4>
          <h4>Bank NL89 INGB 0006738786 tnv Domijn Uden</h4>

          <h3 class="pt-4">Crisis situaties:</h3>
          <h4>
            Mocht u met spoed hulp willen buiten kantooruren, dan kunt u het
            beste bellen met de huisartsenpost in uw eigen regio. Voor de regio
            Uden is dat de Huisartsenpost Oost-Brabant 088 876 5050
          </h4>

          <GmapMap
            id="gmap"
            class="mt-8"
            ref="mapRef"
            :center="center"
            :zoom="13"
            style="width: 100%; height: 300px"
          />
        </v-col>

        <v-col cols="12" md="6" justify="center">
          <div class="formm">
            <form ref="form" @submit.prevent="sendEmail">
              <label>Naam</label>
              <input
                type="text"
                v-model="naam"
                name="name"
                placeholder="Voer uw naam in"
              />
              <label>Email</label>
              <input
                type="email"
                v-model="email"
                name="email"
                placeholder="Voer uw email in"
              />
              <label>Telefoon</label>
              <input
                type="tel"
                v-model="telefoon"
                name="tel"
                placeholder="Voer uw telefoonnummer in"
              />
              <label>Plaats</label>
              <input
                type="text"
                v-model="plaats"
                name="place"
                placeholder="Voer uw plaats in"
              />
              <label>Bericht</label>
              <textarea
                name="message"
                v-model="bericht"
                cols="30"
                rows="5"
                placeholder="Voer hier uw bericht in"
              >
              </textarea>
              <input type="submit" value="Verzenden" />
            </form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { gmapApi } from "vue2-google-maps";
import emailjs from "emailjs-com";

export default Vue.extend({
  name: "Contact",
  computed: {
    google: gmapApi,
  },

  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      this.map = map;
      this.init();
    });
  },
  data() {
    return {
      naam: "",
      email: "",
      telefoon: "",
      plaats: "",
      bericht: "",
      dialog: false,
      center: {
        lat: 51.6563581,
        lng: 5.6385605,
      },
      map: undefined,
    };
  },
  methods: {
    closeDialog() {
      (this.dialog = false),
        // Reset form field
        (this.naam = "");
      this.email = "";
      this.telefoon = "";
      this.plaats = "";
      this.bericht = "";
    },

    sendEmail() {
      var templateParams = {
        naam: this.naam,
        email: this.email,
        telefoon: this.telefoon,
        plaats: this.plaats,
        bericht: this.bericht,
      };

      if (templateParams.bericht.length > 12) {
        emailjs
          .send(
            "service_wggm36e",
            "template_oks6nvr",
            templateParams,
            "user_gk5Uguem7larV7ErG4kVk"
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
      }

      this.dialog = true;
    },

    init() {
      new this.google.maps.Marker({
        position: {
          lat: 51.656386761406495,
          lng: 5.638803631365279,
        },
        map: this.map,
      });
    },
  },
});
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.formm {
  display: block;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  padding: 20px;
  width: 80%;
}

@media only screen and (max-width: 600px) {
  .formm {
    width: 100%;
  }
}

label {
  float: left;
}

input[type="text"],
[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="tel"],
[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #a7955e;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #8c7c2a;
}
</style>
