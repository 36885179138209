<template>
<div class='bg'>
  <v-container class="con">
    <h1 class="light-text">Voor wie?</h1>
    
    
    <v-row class="fill-height" align="center" justify="center">
      <v-col cols="0" md="3" justify="center">
        <v-img 
        class= "hidden-sm-and-down"
        :src="boom"
        contain
        height="400px"/>
      </v-col>
      <v-col cols="12" md="9" justify="center">
        <h4 class="pt-4">{{ text1 }}</h4>
        <h4 class="pt-4">{{ text2 }}</h4>
        <h4>- Niet naar buiten durven</h4>
        <h4>- Bang zijn voor ziektes en gevaar</h4>
        <h4>
          - Depressieve klachten, zoals slecht slapen, weinig eten, negatief zijn
          over jezelf
        </h4>
        <h4>- Eetbuiten, verslavingen zoals durgs, alcohol, gamen</h4>
        <h4>- Onrustig zijn, bepaalde problemen blijven zich herhalen</h4>
        <h4>- Last hebben van een onrustige omgeving</h4>
        <h4>- Psychotische klachten</h4>
        <h4>- Trauma's die nare herbelevingen geven</h4>
        <h4>- Geheugenproblemen</h4>
        <h4>- Problemen in de relatie met anderen</h4>
        <h4 class="pt-4">{{ text3 }}</h4>
      </v-col>
    </v-row>

  </v-container>
</div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "VoorWie",
  data() {
    return {
      text1:
        "Domijn is er voor iedereen boven de 18 jaar die eenmalig advies wil, diagnostiek nodig heeft of iets aan zijn/haar psychische problemen willen gaan doen in de vorm van praktische begeleiding of psychotherapie.",
      text2:
        "Bij Domijn kunt u terecht voor diagnostiek, behandelingen voor angstklachten, stemmingsklachten, trauma’s en persoonlijkheidsproblematiek, zoals:",
      text3:
        "Domijn is niet geschikt voor cliënten die crisisgevoelig zijn of ernstige verslavingsproblemen hebben.",
      boom: require("../assets/boom/boom1.jpg"),
    };
  },
});
</script>

<style scoped>
.bg {
  background-color: #A7955E;
}
</style>