<template>
  <div class="bg">
    <v-container class="con">
      <v-row class="fill-height pt-6" align="center" justify="center">
        <v-col cols="12" md="8" justify="center">
          <h1>Welkom bij Domijn</h1>
          <h2 align="center">{{ introtext }}</h2>
          <h4 class="pt-4">{{ text1 }}</h4>
          <h4 class="pt-4">{{ text2 }}</h4>
        </v-col>
        <v-col cols="12" md="4" align="center">
          <v-img class="pt-12" :src="teamfoto" contain height="360px" />
        </v-col>
      </v-row>
      <h2 class="pt-12">Onze locatie in Uden</h2>
      <h4 class="pt-4">{{ verhuizing }}</h4>
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Welkom",
  data() {
    return {
      introtext:
        "multidisciplinair team voor de behandeling van psychische klachten",
      text1:
        "Domijn is in 2015 van start gegaan. De hulpverleners van Domijn hebben ruime ervaring in de geestelijke gezondheidszorg. Ze hebben brede kennis opgedaan over meerdere behandelvormen van angstklachten, stemmingsklachten, cognitieve problemen en persoonlijkheidsproblematiek. Dat was bij verschillende grote GGZ instellingen. Domijn wil op een kleinschalige manier goede psychische zorg aanbieden.",
      text2:
        "Bij Domijn maken we een zo goed mogelijke inschatting van wat cliënten nodig hebben om vooruit te komen. Samen met u wordt een plan van aanpak gemaakt. Een goede werkrelatie is van groot belang om kleine en grote doelen te bereiken. De behandelingen komen vanuit een breed kader, veelal vanuit een Cognitief Therapeutisch kader: Gedragstherapie; Beeldende Therapie; DGT; Schema Gerichte Psychotherapie; EMDR-therapie en Systeemtherapie. Twee keer per jaar starten we een schematherapie groep voor cliënten met persoonlijkheidsproblematiek. Die duurt drie of vijf maanden.",
      verhuizing:
        "In augustus 2018 zijn we verhuisd naar de Protonenlaan. Het is van buitenaf een unit zonder enige warme uitstraling in een reeks van units waar kleine ondernemers hun ruimte hebben gevonden. Eenmaal binnen bij Domijn proeft u wel een warme sfeer met privacy en rust. U mag kiezen om aan een hoge tafel plaats te nemen of in een zachte fauteuil.",
      Logo: require("../assets/domijn_logov1.png"),
      teamfoto: require("../assets/collegas/teamfoto.jpg"),
    };
  },
});
</script>
