<template>
  <div class="bg">
    <v-container class="con">
      <h1 class="light-text">Vergoedingen</h1>

      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" md="8" justify="center">
          <h4 class="pt-4">
            De vergoedingen zijn ondergebracht in twee soorten zorg
            (Zorgverzekeringswet).
          </h4>
          <div class="column">
              <li> De Generalistische Basis GGZ (GB-GGZ)</li>
              <li> De Gespecialiseerde GGZ (S-GGZ)</li>
          </div>
          <h4>{{ text1 }}</h4>

          <h3 class="pt-4">Vergoeding</h3>
          <h4>
            Domijn heeft voor 2025 contracten afgesloten met alle verzekeraars BEHALVE MENZIS.
            Indien u bij hen verzekerd bent, kunt u wel bij ons in behandeling komen. In dat geval 
            wordt een groot gedeelte van de behandeling niet vergoed door uw verzekeraar.
          </h4>

          <h3 class="pt-4">Verplicht eigen risico</h3>
          <h4>{{ eigenrisico }}</h4>

          <h3 class="pt-4">Zelf betalen</h3>
          <h4>{{ zelfbetalen }}</h4>

          <h3 class="pt-4">No show</h3>
          <h4>{{ noshow }}</h4>
        </v-col>

        <v-col cols="12" md="4" justify="center">
          <v-img :src="boom" contain height="600px" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Vergoeding",
  data() {
    return {
      text1:
        "Domijn biedt behandelingen aan die vallen onder zowel de GB-GGZ als de S-GGZ. Met uw huisarts bespreekt u welke verwijzing u nodig heeft. Hij/zij beslist of er sprake is van enkelvoudige problematiek of complexe problematiek.",
      eigenrisico:
        "Voor verzekerden van 18 jaar en ouder geldt in de basisverzekering een wettelijk verplicht eigen risico van €385,- per kalenderjaar. Dat betekent dat u de eerste €385,- aan kosten zelf moet betalen. Uw eigen risico wordt in het jaar waarin uw behandeling start aangesproken en wordt geïnd door uw zorgverzekeraar. Elk kalenderjaar zal de zorgverzekeraar opnieuw het eigen risico in rekening brengen.",
      zelfbetalen:
        "U kunt er ook voor kiezen om alle  sessies zelf te betalen. Er is dan geen tussenkomst nodig van een verwijzer of verzekeraar. In sommige situaties zijn de kosten fiscaal aftrekbaar. Informeer naar de tarieven en de mogelijkheden bij Domijn.",
      noshow:
        "Het secretariaat van Domijn brengt €55,00 in rekening indien u niet verschijnt op uw afspraak en u zich niet van tevoren met een geldige reden hebt afgemeld. We vragen u om dat met voorkeur minimaal 24 uur van tevoren te doen.",
      boom: require("../assets/boom/boom2.jpg"),
    };
  },
});
</script>

<style scoped>
.bg {
  background-color: #a7955e;
}
.column-container {
  display: flex;
}
.column {
  flex: 1;
  padding: 10px;
}
</style>
