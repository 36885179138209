<template>

<div class="bg">
  <v-container class="con">
    <h1>Aanmelding</h1>
    <h4 class="pt-4">{{ text1 }}</h4>
    <h4 class="pt-4">{{ text2 }}</h4>
    <h4 class="pt-4">{{ text3 }}</h4>
    <h4 class="pt-4">{{ text4 }}</h4>
    <h4 class="pt-4">{{ text5 }}</h4>
    <h4 class="pt-4">{{ text6 }}</h4>
    <h1 class="pt-4">Crisis-situaties</h1>
    <h4 class="pt-4">{{ crisis1 }}</h4>
    <h4 class="pt-4">{{ crisis2 }}</h4>
    <h1 class="pt-4">Wachtlijstbemiddeling</h1>
    <h4 class="pt-4">{{ bemiddeling1 }}</h4>
  </v-container>
</div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Aanmelding",
  data() {
    return {
      text1:
        "Iedere vraag om diagnostiek of behandeling van de klachten kan pas van start gaan met een verwijzing van de huisarts.",
      text2:
        "Als u zich wilt aanmelden, kunt u het beste even bellen: 0413 760560: Laat een bericht achter en u wordt binnen een werkdag teruggebeld.",
      text3:
        "Domijn heeft een wachtlijst van ongeveer 50 weken! De wachtlijst is daarom gesloten.",
      text4:
        "Na een intake krijgt u een adviesgesprek. Met uw hulpverlener bespreekt u hierin wat de voorlopige diagnose is en welke behandelingen daar bij kunnen passen. Samen met de hulpverlener maakt u de beslissing over de start van een behandelprogramma, de duur daarvan en de doelen die bereikt kunnen worden.",
      text5:
        "Zo nodig wordt extra diagnostiek ingepland. De start van de behandeling kan beginnen als het behandelplan er ligt. Deze sluit meestal aan op de intakeperiode.",
      text6:
        "Er zijn behandeltrajecten (B-GGZ) die 6-10 sessies duren. Er zijn ook langere trajecten mogelijk (S-GGZ) wanneer in de intake blijkt dat er meer complexe problemen aan de orde zijn.",
      crisis1:
        "Mocht u met spoed hulp willen buiten kantooruren, dan kunt u het beste bellen met de huisartsenpost in uw eigen regio. Voor de regio Uden is dat de Huisartsenpost Oost-Brabant 088 876 5050",
      crisis2:
        "Domijn is overdag meestal goed te bereiken per telefoon en mail. We bellen u (indien u bij ons cliënt bent) dan z.s.m. terug. Bij uitvallen van hulpverleners van Domijn doen we zo nodig een beroep op onze vaste waarnemer. Dat is mevrouw Marie-Jozé de Vroom, klinisch psycholoog en psychotherapeut, Goudrenet 4 in Uden. Haar mobiele nummer is 06-23733027",
      bemiddeling1:
        "Wanneer je de wachttijd bij Domijn te lang vindt, dan kun je altijd contact opnemen met jouw zorgverzekeraar en vragen om wachtlijstbemiddeling. Jouw zorgverzekeraar kan je ondersteunen in het zoeken naar een andere zorgaanbieder, zodat je binnen vier weken vanaf het eerste contact met een zorgaanbieder een intakegesprek krijgt, en dat binnen tien weken vanaf de intake de behandeling is gestart. Dit zijn de maximaal aanvaardbare wachttijden die door zorgaanbieders en zorgverzekeraars gezamenlijk zijn overeengekomen (de treeknormen).",
    };
  },
});
</script>
